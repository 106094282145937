
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
    onBeforeMount,
  } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  import { Shipment } from '@/store/modules/Shipments/ShipmentsModule';

  export default defineComponent({
    name: 'shipment-packages',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitVideoButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const createdShipment = ref<Shipment | null>(null);
      const selectedShipment = ref<string>('');
      const areaName = ref<string>('');
      const driverName = ref<string>('');
      const selectedUnassignedPackages = ref<Array<string>>([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      watch(
        () => selectedShipment.value,
        (newVal, oldVal) => {
          const selectedShipment = shipments.find(
            (shipment) => shipment.id === newVal
          );
          if (selectedShipment) {
            areaName.value = selectedShipment.areaName;
            driverName.value = selectedShipment.driverName;
          } else {
            areaName.value = '';
            driverName.value = '';
          }
        }
      );

      const shipmentsSchema = Yup.object().shape({
        shipmentId: Yup.string()
          .notOneOf([''], () => translate('SHIPMENT_IS_REQUIRED_FIELD'))
          .required(() => translate('SHIPMENT_IS_REQUIRED_FIELD'))
          .label('shipmentId'),
        packages: Yup.number()
          .min(1, 'At least one package is required')
          .label('Packages'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        delete values.driverName;
        delete values.areaName;
        delete values.packages;

        values.packageIds = packagesArray.value;
        const result = await store.dispatch(
          Actions.CREATE_UNASSIGNED_PACKAGE,
          values
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdShipment.value = result;
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PACKAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'unassigned-listing' });
          });
        }
      };
      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('packagesCreating'), [
          translate('packages'),
        ]);
        reinitializeComponents();
      });

      const packagesArray = computed(() => store.getters.getPackagesArray);

      const shipments = await store.dispatch(Actions.GET_ALL_HUB_SHIPMENTS);

      return {
        submitVideoButton,
        onSubmitCreate,
        submitButton,
        translate,
        shipmentsSchema,
        shipments,
        goBack,
        can,
        driverName,
        areaName,
        loading,
        createdShipment,
        packagesArray,
        selectedShipment,
        selectedUnassignedPackages,
      };
    },
  });
